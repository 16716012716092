<template>
  <div>
    <WritePost class="mb-3" />
    <b-overlay :show="isLoadingFirst">
      <div :class="scrollClass">
        <div v-for="(post, index) in posts" :key="index">
          <Post
            class="mb-3"
            :index="index"
            :post="post"
            @deletePost="deletePost($event)"
          />
        </div>
      </div>
      <b-overlay :show="isLoadingNext">
        <b-button class="mb-3" block v-if="hasNext" @click="fetchNext">
          Load more
        </b-button>
      </b-overlay>
    </b-overlay>
  </div>
</template>
<script>
import WritePost from "@/components/landing/WritePost.vue";
import Post from "@/components/landing/Post.vue";
import moment from "moment";
import Pusher from "pusher-js"; // import Pusher
import SocialPosts from "@/store/modules/socialposts.module";
import { SocialPost } from "@/models/social-post.model";

export default {
  props: ["hideScroll"],
  components: {
    WritePost,
    Post,
  },
  data() {
    return {
      isLoadingNext: false,
      isLoadingFirst: false,
      pusherChannel: null,
    };
  },
  async created() {
    this.isLoadingFirst = true;
    await this.$store.dispatch("SocialPosts/fetchAll");
    this.isLoadingFirst = false;
    this.updatePosts();
  },
  destroyed() {
    this.pusherChannel.unbind();
  },
  methods: {
    postAlreadyLoaded(socialPost) {
      return Boolean(
        this.posts.find((item) => {
          return item.id === socialPost.id;
        })
      );
    },
    isPostOfCurrentUser(socialPost) {
      return Boolean(this.currentUser?.id === socialPost.user.id);
    },
    async fetchNext() {
      this.isLoadingNext = true;
      await this.$store.dispatch("SocialPosts/fetchNext");
      this.isLoadingNext = false;
    },
    updatePosts() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: "eu",
      });
      this.pusherChannel = pusher.subscribe("socialposts");
      this.pusherChannel.bind(window.origin, (data) => {
        const post = data.SocialPost;
        if (this.postAlreadyLoaded(post) || this.isPostOfCurrentUser(post)) {
          return;
        }
        this.isLoadingFirst = true;
        this.$store.commit("SocialPosts/PREPEND_POST", data.SocialPost);
        setTimeout(() => {
          this.isLoadingFirst = false;
        }, 500);
      });
    },
  },
  computed: {
    scrollClass() {
      return this.hideScroll ? "" : "scrollableSocial";
    },
    posts() {
      return this.$store.state.SocialPosts.all;
    },
    hasNext() {
      return this.$store.state.SocialPosts.lastPagination?.next_page_url;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
};
</script>
<style scoped>
.scrollableSocial {
  min-height: 75vh;
  max-height: 100vh;
  overflow-y: scroll;
  background: transparent;
}
</style>
