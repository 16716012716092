<template>
  <b-overlay :show="creatingPost">
    <div class="card">
      <div class="card-body">
        <!-- Form -->

        <div class="col-12 text-center mb-2">
          <small class="text-muted"> {{ $t("socialWallWarning") }} </small>
        </div>
        <form>
          <div class="form-group">
            <textarea
              class="form-control form-control-auto"
              data-autosize=""
              rows="3"
              v-model="post.post"
              placeholder="Start a post..."
              style="overflow: hidden; overflow-wrap: break-word; height: 68px"
            ></textarea>
          </div>
        </form>
        <div class="row align-items-center">
          <div class="col">
            <small class="text-muted"> </small>
          </div>
          <div class="col-auto">
            <!-- Icons -->
            <div class="text-muted">
              <input
                accept=".jpg, .jpeg, .png, .gif"
                type="file"
                id="upload-image"
                name="upload-image"
                @change="onImageChange($event)"
                hidden
              />
              <a
                class="text-reset mr-3"
                data-bs-toggle="tooltip"
                refs="upload-image"
                data-bs-original-title="Add photo"
                @click="chooseImage()"
              >
                <font-awesome-icon class="fe" icon="camera"></font-awesome-icon>
              </a>
              <input
                @change="onAttachmentChange($event)"
                accept=".pptx, .ppt, .pptm, .ppsx, .pps, .ppsm, .potx, .pot, .potm, .rar, .zip, .pdf, .xl, .xlsx, .xlsm, .xlsb, .xlam, .xltx, .xltm, .xls, .xlt, .xla, .xlm, .xlw, .uxdc, .docx, .docm, .doc, .dot, .dotx, .dot, .dotm, .rtf, .xml"
                type="file"
                id="upload-attachment"
                name="upload-attachment"
                hidden
              />
              <a
                class="text-reset mr-3"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Attach file"
                @click="chooseAttachment()"
              >
                <font-awesome-icon
                  class="fe"
                  icon="paperclip"
                ></font-awesome-icon>
              </a>
              <b-button @click="createPost()" class="create-post-button"
                >Create post</b-button
              >
            </div>
          </div>
        </div>
        <b-overlay :show="true" v-if="uploadingFile">
          Uploading file...
        </b-overlay>
        <b-img
          v-if="post.attachment_type === 'image'"
          class="w-25"
          thumbnail
          :src="fileUrl"
        ></b-img>
        <a v-if="post.attachment_type === 'file'">
          {{ attachmentName }}<br />
        </a>
        <!-- Footer -->
      </div>
    </div>
  </b-overlay>
</template>
<script>
import axios from "@/plugins/axios.plugin";
import { SocialPost } from "@/models/social-post.model";
import moment from "moment";

export default {
  data() {
    return {
      fileUrl: null,
      attachmentName: "",
      imageUploaded: false,
      uploadingFile: false,
      creatingPost: false,
      attachmentUploaded: false,
      post: new SocialPost("", ""),
    };
  },
  created() {
    this.post.user_id = this.userId;
  },
  computed: {
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    userId() {
      return this.currentUser?.id;
    },
  },
  methods: {
    chooseImage() {
      document.getElementById("upload-image").click();
    },
    chooseAttachment() {
      document.getElementById("upload-attachment").click();
    },
    async onImageChange(event) {
      this.attachmentUploaded = false;
      this.imageUploaded = true;
      await this.uploadFile(event.target.files[0], "image");
    },
    async onAttachmentChange(event) {
      this.imageUploaded = false;
      this.attachmentUploaded = true;
      await this.uploadFile(event.target.files[0], "file");
    },
    async uploadFile(file, type) {
      this.uploadingFile = true;
      let formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/files/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
      });
      this.fileUrl = process.env.VUE_APP_FILE_URL + response.data.url;
      this.post.attachment_type = type;
      this.post.attachment_url = response.data.url;
      this.post.attachment_name = file.name;
      this.uploadingFile = false;
    },
    async createPost() {
      this.creatingPost = true;
      await this.$store.dispatch("SocialPosts/create", this.post);
      this.post = new SocialPost();
      this.creatingPost = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.class {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.5 rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.fe {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Feather !important;
  font-style: normal;
  font-variant: normal;
  line-height: inherit;
  text-transform: none;
}

.link {
  color: #118bee;
}
</style>
