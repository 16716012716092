<template>
  <b-overlay :show="isLoading">
    <div class="card">
      <div class="card-body">
        <!-- Header -->
        <div class="mb-3">
          <div class="row align-items-center">
            <!-- Avatar -->
            <div class="col-auto">
              <b-avatar
                variant="info"
                :text="userInitials"
                :src="profilePicture"
              ></b-avatar>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <b class="mb-1">{{ userName }}</b>

              <!-- Time -->
              <p class="card-text small text-muted">
                <font-awesome-icon
                  icon="clock"
                  class="mr-2"
                ></font-awesome-icon>
                <time datetime="2018-05-24">{{
                  post.created_at | moment("from")
                }}</time>
              </p>
            </div>
            <div
              class="col-auto"
              v-if="
                currentUser.role == 'moderator' || currentUser.role == 'admin'
              "
            >
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="deletePost">
                  Delete Post
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <!-- / .row -->
        </div>

        <!-- Text -->
        <p class="mb-3">
          {{ post.post }}
        </p>

        <!-- Image -->
        <b-img
          center
          thumbnail
          fluid
          v-if="hasImage"
          :src="attachmentUrl"
        ></b-img>

        <!-- Attachment -->
        <a v-else :href="attachmentUrl" class="link" download>
          {{ attachmentName }}</a
        >
      </div>
    </div>
  </b-overlay>
</template>
<script>
import moment from "moment";

export default {
  props: ["post", "index"],
  data() {
    return {
      isLoading: false,   
    };
  },
  methods: {
    async deletePost() {
      const result = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to delete this post?",
        {
          title: "Confirm delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (result) {
        this.isLoading = true;
        try {
          await this.$store.dispatch("SocialPosts/delete", this.post.id);
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    userName() {
      if (!this.post.user) {
        return "Unknown";
      }
      return `${this.post.user.first_name} ${this.post.user.last_name}`;
    },
    userInitials() {
      return `${this.post?.user?.first_name?.charAt(
        0
      )}${this.post?.user?.last_name?.charAt(0)}`;
    },
    profilePicture() {
      return this.post?.user?.profile_image_url;
    },
    hasImage() {
      return this.post.attachment_type === "image";
    },
    attachmentUrl() {
      return process.env.VUE_APP_FILE_URL + this.post.attachment_url;
    },
    attachmentName() {
      return this.post.attachment_name;
    },
  },
};
</script>
<style>
.dropdown {
  position: relative;
}

.link {
  color: #118bee;
}
</style>
